export default [
  {
    id: 1,
    name: 'Главная',
    alias: 'main',
  },
  {
    id: 2,
    name: 'Каталог',
    alias: 'catalog',
  },
  {
    id: 3,
    name: 'Карточка авто',
    alias: 'auto',
  },
  {
    id: 4,
    name: 'Проверка авто',
    alias: 'check',
  },
  {
    id: 5,
    name: 'О нас',
    alias: 'about_us',
  },
  {
    id: 6,
    name: 'Контакты',
    alias: 'contacts',
  },
]
