<template>
  <a-table :columns="columns" :dataSource="list" :row-key="(record, index) => record.id + index">
    <a
      slot="id"
      slot-scope="text"
      href="javascript: void(0);"
      class="btn btn-sm btn-light"
    >{{text}}</a>
    <span slot="name" slot-scope="record"> {{ record.name || record.value }}</span>
    <span slot="action" slot-scope="record">
            <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="record.alias === 'contacts' ? $router.push({ path: `/content/${record.alias}` }) : $router.push({ path: `/content/all_pages/${record.alias}` })">
              <i class="fe fe-edit mr-2" />
              Изменить
            </a>
          </span>
  </a-table>
</template>

<script>
import pages from '@/entities/pages'

export default {
  name: 'AllPagesTabel',
  data() {
    return {
      list: pages,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
