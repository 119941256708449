<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Контент: Страницы сайта</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Редактирование настроек</h5>
        </div>
      </div>
      <div class="card-body">
        <all-pages-table />
      </div>
    </div>
  </div>
</template>

<script>
import AllPagesTable from '@/components/content/pages/AllPagesTable'

export default {
  name: 'index',
  components: { AllPagesTable },
}
</script>

<style scoped>

</style>
